<template>
  <div>
    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <h4>อัพสลิปธนาคาร ย้อนหลัง</h4>
          <br>
          <div class="flex-grow">
            <div class="vx-card__title">
              <h4>เลือกวันที่ทำรายการโอนเงิน</h4>
              <br>
            </div>
            <flat-pickr v-model="date" />
            <p class="mt-4">Selected Date: {{ date }}</p>
          </div>

          <div class="mt-8">
            <vs-input class="w-60" v-validate="'required'" label="username" v-model="username" name="username" />
          </div>

          <div class="mt-8">
            <vs-input class="w-60" v-validate="'required|decimal'" label-placeholder="จำนวนเงิน" v-model="amount"
              name="amount" />
            <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first('amount') }}</span>
          </div>

          <vs-button color="warning" type="relief" class="mt-8" @click="getData">ค้นหารายการ</vs-button>

          <div v-if="dataTrans.length > 0">
            <vs-table stripe max-items="20" pagination :data="dataTrans" search>

              <template slot="thead">
                <vs-th>รายการที่</vs-th>
                <vs-th>วัน/เวลา</vs-th>
                <vs-th>USERNAME</vs-th>
                <vs-th>ยอดเงิน</vs-th>
                <vs-th>ข้อมูล</vs-th>
                <vs-th>Admin</vs-th>
                <vs-th>คลิกเลือกรายการ</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                  <vs-td :data="tr.wd_id">
                    {{tr.wd_id}}
                  </vs-td>

                  <vs-td :data="tr.wd_datetime">
                    {{moment(tr.wd_datetime).format('YYYY-MM-DD HH:mm:ss')  }}
                  </vs-td>

                  <vs-td class="text-danger">
                    <router-link :to="`/member/${data[indextr].wd_username}`">{{ data[indextr].wd_username}}
                    </router-link>
                  </vs-td>

                  <vs-td :data="tr.wd_amount">
                    {{currency(tr.wd_amount)}}
                  </vs-td>

                  <vs-td :data="tr.wd_info">
                    {{tr.wd_info}}
                  </vs-td>

                  <vs-td :data="tr.wd_admin">
                    {{tr.wd_admin}}
                  </vs-td>

                  <vs-td :data="tr.wd_id">
                    <vs-button color="success" type="relief" class="mt-8" @click="withdrawID=tr.wd_id">เลือก</vs-button>
                  </vs-td>

                </vs-tr>
              </template>
            </vs-table>

            <br>

            <vs-input class="w-60 mb-3" readonly v-validate="'required|decimal'" label-placeholder="รายการที่เลือก" v-model="withdrawID"
              name="withdrawID" />
            <label> อัพสลิปธนาคาร COPY & PASTE </label>
            <div class="mt-2">
              <!-- <vs-input class="inputx" placeholder="กรุณาวางรูป"/> -->
              <vs-input class="inputx" placeholder="กรุณาวางรูป" :value="selectedFile.name" @paste="onPaste" />
              <vs-checkbox disabled="true" class="mt-2" :color="chk_box.color" v-model="chk_box.data">{{chk_box.text}}
              </vs-checkbox>
              <canvas style="border:1px solid grey;" id="mycanvas" width="100" height="100" ></canvas>
            </div>

            <vs-button color="success" type="relief" class="mt-8" @click="onUploadFile">อัพโหลดรูป</vs-button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'
import axios from '../../axios'
import * as imageConversion from 'image-conversion'
import {
  Validator
} from 'vee-validate'
const dict = {
  custom: {
    amount: {
      required: 'กรุณากรอกข้อมูล',
      decimal: 'ต้องเป็นตัวเลขหรือจุดทศนิยมเท่านั้น'
    },
    info: {
      required: 'กรุณากรอกข้อมูล'
    }
  }
}
Validator.localize('en', dict)


export default {
  components: {
    flatPickr
  },
  data () {
    return {
      date: moment.tz('Asia/Bangkok').format('YYYY-MM-DD'),
      username: '',
      amount: '',
      // onPaste: [],
      selectedFile: [],
      chk_box: {
        text: 'กรุณาวางรูป',
        color: 'danger',
        data: false
      },
      dataTrans: [],
      withdrawID: ''
    }
  },
  async mounted () {

  },
  methods: {
    async getData () {
      const dataTransactions = await axios
        .get(
          `bankwithdraw/list/withdraw/transactions/${this.username}/${this.amount}/${this.date}`
        )

      if (dataTransactions.data.status) {
        this.dataTrans = dataTransactions.data.data
        if (dataTransactions.data.data.length < 1) {
          this.$vs.notify({
            time: 10000,
            color: 'danger',
            position: 'top-right',
            icon: 'error',
            title: 'หารายการไม่พบ',
            text: 'หารายการไม่พบ'
          })
        }
      } else {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: dataTransactions.data.info,
          text: dataTransactions.data.info
        })
      }
    },
    async compressFile () {
      const res = await imageConversion.compress(this.selectedFile, {
        size: 100,
        quality: 0.5,
        type: 'image/jpg'
        // width: 300,
        // height: 200,
        // scale: 0.5
      })
      this.selectedFile = res
    },
    async onUploadFile () {
      await this.compressFile()
      const formData = new FormData()
      formData.append('path', 'withdraw')
      formData.append('file', this.selectedFile)
      formData.append('topupid', this.withdrawID) // appending file

      // sending file to the backend
      const respone = await axios
        .post('aws3/upload/upload', formData)
        .then()
        // .catch(err => { console.log('errja', err) })
      //   console.log(respone)
      if (respone.status === 200 && respone.data) {
        this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'error',
          title: 'อัพโหลดรูปสำเร็จ',
          text: 'อัพโหลดรูปสำเร็จ'
        })
      }
    },
    retrieveImageFromClipboardAsBlob (pasteEvent, callback) {
      if (pasteEvent.clipboardData === false) {
        if (typeof callback === 'function') {
          callback(undefined)
        }
      }

      const items = pasteEvent.clipboardData.items

      if (items === undefined) {
        if (typeof callback === 'function') {
          callback(undefined)
        }
      }

      for (let i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf('image') === -1) continue
        // Retrieve image on clipboard as blob
        const blob = items[i].getAsFile()

        if (typeof callback === 'function') {
          callback(blob)
          this.selectedFile = blob
        }
      }
    },
    onPaste (evt) {
      // console.log('on paste', evt)
      // Handle the event
      this.retrieveImageFromClipboardAsBlob(evt, function (imageBlob) {
        // If there's an image, display it in the canvas
        if (imageBlob) {
          const canvas = document.getElementById('mycanvas')
          const ctx = canvas.getContext('2d')

          // Create an image to render the blob on the canvas
          const img = new Image()

          // Once the image loads, render the img on the canvas
          img.onload = function () {
            // Update dimensions of the canvas with the dimensions of the image


            // Draw the resized image
            const resizedWidth = 300 // Adjust the resized width as needed
            const resizedHeight = (img.height / img.width) * resizedWidth

            canvas.width = resizedWidth
            canvas.height = resizedHeight

            ctx.drawImage(img, 0, 0, resizedWidth, resizedHeight)
            // Draw the image


            // Display a smaller version of the image
            const thumbnailCanvas = document.getElementById('thumbnailCanvas')
            const thumbnailCtx = thumbnailCanvas.getContext('2d')
      
            const thumbnailSize = 100 // Adjust the size as needed
            thumbnailCanvas.width = thumbnailSize
            thumbnailCanvas.height = thumbnailSize
            thumbnailCtx.drawImage(img, 0, 0, img.width, img.height, 0, 0, thumbnailSize, thumbnailSize)
          }

          // Crossbrowser support for URL
          const URLObj = window.URL || window.webkitURL

          // Creates a DOMString containing a URL representing the object given in the parameter
          // namely the original Blob
          img.src = URLObj.createObjectURL(imageBlob)
        }
      })
    },
    currency (amount) {
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        amount = String(Math.abs(amount))
        amount = (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        // console.log(amount)
        return `-${amount}`
      }
    }
    // chooseWD (withdrawID) {

    // }
  },
  watch: {
    selectedFile () {
      if (this.selectedFile.name.length > 0) {
        this.chk_box.text = 'วางรูปสำเร็จ'
        this.chk_box.color = 'success'
        this.chk_box.data = true
      } else {
        this.chk_box.text = 'วางรูปไม่สำเร็จ'
        this.chk_box.color = 'danger'
        this.chk_box.data = false
      }
    },
    date (newValue) {
      this.date = newValue
    }
  }
}

</script>

<style>

</style>
